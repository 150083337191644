import React, { Component } from "react";
import { enquireScreen } from "enquire-js";
// import Blogs from "../components/Home/Blogs";
import { injectIntl } from "gatsby-plugin-intl";
import loadable from "@loadable/component";

import {
  Banner50DataSource,
  Feature45DataSource,
  Feature46DataSource,
  DeploymentDataSource,
  ContactFormDataSource,
  Feature100DataSource,
  Feature101DataSource,
  CustomersDataSource
} from "../data/home.data";

const Layout = loadable(() => import("../components/Layout"));
const SEO = loadable(() => import("../components/SEO"));
const Banner = loadable(() => import("../components/Banner"));
const Feature45 = loadable(() => import("../components/Home/Feature45"));
const Feature100 = loadable(() => import("../components/Home/Feature100"));
const Feature101 = loadable(() => import("../components/Home/Feature101"));
const Deployment = loadable(() => import("../components/Home/Deployment"));
const Customers = loadable(() => import("../components/Home/Customers"));
const ContactForm = loadable(() => import("../components/Home/ContactForm"));

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile
    };
  }

  componentDidMount() {
    enquireScreen(b => {
      this.setState({ isMobile: !!b });
    });
  }

  render() {
    const { intl } = this.props;

    return (
      <>
        <SEO
          title="Computer Vision Vietnam | CVS"
          pathname="/"
          description="Computer Vision Vietnam (CVS) cung cấp các công cụ thông minh giúp doanh nghiệp tự động hóa và chuyển đổi số nhanh chóng trong thời đại 4.0"
          keywords="Computer Vision"
        />
        <Layout>
          <Banner
            id="Banner0_0"
            key="Banner0_0"
            dataSource={Banner50DataSource}
            isMobile={this.state.isMobile}
          />
          <Customers
            id="Customers"
            key="Customers"
            dataSource={CustomersDataSource}
            isMobile={this.state.isMobile}
          />
          <Feature100
            id="Feature100"
            key="Feature100"
            dataSource={Feature100DataSource}
            isMobile={this.state.isMobile}
          />
          <Feature101
            id="Feature101"
            key="Feature101"
            dataSource={Feature101DataSource}
            isMobile={this.state.isMobile}
          />
          <Deployment
            id="Deployment"
            key="Deployment"
            dataSource={DeploymentDataSource}
            isMobile={this.state.isMobile}
          />
          {/* <Blogs
            id="Blogs"
            key="Blogs"
            dataSource={posts}
            isMobile={this.state.isMobile}
          /> */}
          <ContactForm
            id="ContactForm"
            key="ContactForm"
            dataSource={ContactFormDataSource}
            isMobile={this.state.isMobile}
          />
          <Feature45
            id="Feature4_5"
            key="Feature4_5"
            dataSource={
              intl.locale === "vi" ? Feature45DataSource : Feature46DataSource
            }
            isMobile={this.state.isMobile}
          />
        </Layout>
      </>
    );
  }
}

export default injectIntl(IndexPage);
